﻿[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;

  &:before,
  &:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }

  &:before {
    position: absolute;
    bottom: 77%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 145px;
    border-radius: 4px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: white;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 13px;
    line-height: 1.5;
  }

  &:after {
    position: absolute;
    bottom: 77%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  &:hover {
    &:before,
    &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
    }
  }
}
